import Marquee from './modules/marquee'

if (document.getElementById("clients-right")) {
  let clientsRight = Marquee();
  const clients = "TV 2    NextGenTel    EY    MOVA    NOF    Quala    Amitec    Ava of Norway    Adaptic Technology    Ulriken643    Vipps    Sterling White Halibut    SRF    NCE Finance Innovation    "
  clientsRight.init(document.getElementById("clients-right"), {
    text: clients,
    fontSize: 25,
    font: "Inter UI",
    bgColor: "transparent",
    gap: 10,
    speed: 2,
    color: "white",
    direction: "left-to-right" // or "left-to-right"
  });
  let clientsLeft = Marquee();
  clientsLeft.init(document.getElementById("clients-left"), {
    text: clients,
    fontSize: 25,
    font: "Inter UI",
    bgColor: "transparent",
    gap: 10,
    speed: 3.9,
    color: "white",
    direction: "right-to-left" // or "left-to-right"
  });
  let clientsLeft2 = Marquee();
  clientsLeft2.init(document.getElementById("clients-left-2"), {
    text: clients,
    fontSize: 25,
    font: "Inter UI",
    bgColor: "transparent",
    gap: 10,
    speed: 3.5,
    color: "white",
    direction: "right-to-left" // or "left-to-right"
  });
  window.addEventListener('resize', function() {
    clientsLeft.reset();
    clientsRight.reset();
    clientsLeft2.reset();
  });
}
if (document.getElementById("marquee-innovation")) {
  let innovationMarquee = Marquee();
  innovationMarquee.init(document.getElementById("marquee-innovation"), {
    text: "siste opptak    siste opptak    siste opptak    siste opptak    siste opptak    siste opptak    siste opptak    siste opptak    ",
    fontSize: 36,
    font: "Inter UI",
    bgColor: "#ffd84e",
    gap: 10,
    speed: 3,
    color: "#000",
    direction: "right-to-left" // or "left-to-right"
  });
  window.addEventListener('resize', function() {
    innovationMarquee.reset();
  });
}



